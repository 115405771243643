@charset "utf-8";
/* CSS Document */

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Bold.woff2') format('woff2'),
        url('../fonts/TIMSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Thin.woff2') format('woff2'),
        url('../fonts/TIMSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-LightItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Heavy.woff2') format('woff2'),
        url('../fonts/TIMSans-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-HeavyItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Medium.woff2') format('woff2'),
        url('../fonts/TIMSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Italic.woff2') format('woff2'),
        url('../fonts/TIMSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Light.woff2') format('woff2'),
        url('../fonts/TIMSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-MediumItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-ThinItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Regular.woff2') format('woff2'),
        url('../fonts/TIMSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'TIM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: url('../images/tela_de_manu_2.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.not-found section {
    width: 600px;
    margin: 0 auto;
    text-align: center;
}

.not-found section img {
    width: 40%;
    height: auto;
}

.not-found section h1 {
    font-size: 40px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: normal;
    margin: 20px 0px;
}

.not-found section hr {
    border: 0;
    border-bottom: 1px solid #94c72b;
}

.not-found section h2 {
    font-size: 25px;
    font-weight: normal;
    color: #ffffff;
    margin: 30px 0;
}

@media (max-width: 768px) {
    body {
        background: url('../images/tela_de_manu_2_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .not-found section {
        width: 90%;
    }

    .not-found section h1 {
        font-size: 25px;
        margin: 20px 0;
    }

    .not-found section h2 {
        font-size: 18px;
        margin: 20px 0;
    }
}
