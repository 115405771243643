@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Bold.woff2') format('woff2'),
        url('../fonts/TIMSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Thin.woff2') format('woff2'),
        url('../fonts/TIMSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-LightItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Heavy.woff2') format('woff2'),
        url('../fonts/TIMSans-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-HeavyItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Medium.woff2') format('woff2'),
        url('../fonts/TIMSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Italic.woff2') format('woff2'),
        url('../fonts/TIMSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Light.woff2') format('woff2'),
        url('../fonts/TIMSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-MediumItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-ThinItalic.woff2') format('woff2'),
        url('../fonts/TIMSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'TIM Sans';
    src: url('../fonts/TIMSans-Regular.woff2') format('woff2'),
        url('../fonts/TIMSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
* {
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'TIM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    display: block;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.float-btn .float-btn-content {
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 99999999;
    background-color: #41acf5;
    border-radius: 50px;
    height: 70px;
    width: 210px;
    padding: 5px;
}
.float-btn .float-btn-content i svg {
    width: 60px;
    height: 60px;
}
.float-btn .float-btn-content .float-btn-text {
    display: inline-block;
    position: relative;
    top: -5px;
    margin-left: 15px;
    color: #fff;
}
.float-btn .float-btn-content p strong {
    display: block;
    font-size: 16px;
    font-family: 'TIM Sans';
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 0px;
}
.float-btn .float-btn-content p strong em {
    display: block;
    font-family: 'TIM Sans';
    font-size: 16px;
    font-weight: bold;
}
.float-btn .float-btn-content p:last-child {
    display: block;
    margin-top: -10px;
    font-family: 'TIM Sans';
    font-size: 15px;
}

header {
    border-bottom: 1px solid #ffffff;
    background-color: #002198;
    padding: 15px 0 10px 0;
}
header img {
    width: 80px;
    height: auto;
}

header .numero-login {
    float: right;
    width: auto;
    margin-top: 5px;
}
header .numero-login p {
    display: inline-block;
    color: #fff;
    font-family: 'TIM Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 0;
    margin-right: 15px;
}
header .numero-login p::after {
    content: '';
    display: inline-block;
    height: 15px;
    width: 1px;
    background-color: #fff;
    margin-left: 15px;
}

header .numero-login a {
    color: #41acf5;
    text-decoration: none;
    font-size: 14px;
    line-height: 0;
}

.content {
    padding-top: 30px;
}
.banner {
    background: url('../images/bg-desk.png') no-repeat;
    background-size: cover;
    padding: 0;
    overflow: hidden;
    min-height: 360px;
}
.banner .hero {
    width: 400px;
    height: auto;
    display: block;
}
.banner h2 {
    padding-top: 50px;
    color: #fff;
    font-size: 30px;
}

.banner h2 em {
    font-size: 30px;
    color: #41acf5;
}
.banner h2 strong {
    display: block;
}
.banner h2 span {
    color: #6fc713;
}

.banner h3 {
    padding-top: 10px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
}
.banner p {
    padding: 15px 0 0 0;
    color: #fff;
    vertical-align: top;
}
.banner p img {
    vertical-align: top;
}
.banner em {
    font-size: 13px;
    font-style: normal;
    color: #fff;
    padding-bottom: 15px;
}
.banner .giga-desconto {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 426px;
}
.banner .giga {
    font-size: 73px;
    color: #6fc713;
    font-weight: bold;
}
.banner .line {
    height: 60px;
    border-left: 2px solid #ffffff;
}
.banner .desconto {
    padding-top: 0 !important;
    font-size: 18px;
}
.banner .desconto em {
    font-size: 19px;
}
.banner .desconto strong {
    font-size: 38px;
}
.banner button[type='submit'] {
    display: block;
    width: 190px;
    height: 75px;
    line-height: 75px;
    border-radius: 22px;
    background-color: #ffffff;
    text-align: center;
    text-decoration: none;
    color: #002198;
    font-size: 24px;
    float: right;
    margin: -92px 45px 0 0;
    border: none;
}
.banner button[type='submit']:focus {
    outline: none;
}
.box {
    padding: 23px 10px 70px 10px;
}
.box:focus {
    outline: none !important;
}
.blue {
    background-image: -webkit-linear-gradient(256deg, #002198, #05103e);
    background-image: linear-gradient(194deg, #002198, #05103e);
    padding: 20px 0 0 0;
    border-radius: 10px;
}
.blue h2 {
    font-weight: 700;
    text-align: center;
    font-size: 50px;
    color: #6fc713;
    padding-top: 15px;
}
.blue h2 strong {
    font-size: 50px;
}
.blue h2 span {
    display: block;
    font-size: 15px;
    letter-spacing: -1px;
    padding-top: 5px;
    color: #fff;
    font-weight: 600;
}
.blue .apps {
    padding: 20px 0;
    text-align: center;
}
.blue .apps h3 {
    font-size: 16px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    background-image: url('../images/icn_apps.svg');
    background-repeat: no-repeat;
    background-position: 20px top;
    padding: 7px 0 15px 0;
}
.blue .apps img {
    width: 30px;
    margin: 0 2px;
    height: auto;
    display: inline-block;
}
.blue .ligacoes {
    padding: 15px 0 15px 0;
    text-align: center;
    background-image: -webkit-linear-gradient(221deg, #05103e, #002198);
    background-image: linear-gradient(229deg, #05103e, #002198);
}
.blue .ligacoes h3 {
    font-size: 16px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    background-image: url('../images/icn_sms.svg');
    background-repeat: no-repeat;
    background-position: 20px top;
    padding: 7px 60px 15px 60px;
}
.blue .ligacoes h3 span {
    font-weight: 400;
    display: block;
    font-size: 12px;
}
.blue .redes-sociais {
    padding: 15px 0 15px 0;
    text-align: center;
    background-image: -webkit-linear-gradient(221deg, #05103e, #002198);
    background-image: linear-gradient(229deg, #05103e, #002198);
}
.blue .redes-sociais h4 {
    font-size: 16px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    background-image: url('../images/icn_social.svg');
    background-repeat: no-repeat;
    background-position: 20px top;
    padding: 7px 60px 15px 60px;
}
.blue .redes-sociais img {
    width: 30px;
    margin: 0 3px;
    height: auto;
    display: inline-block;
}
.blue .price {
    padding: 20px 0;
    text-align: center;
    color: #fff;
}
.blue .price strong {
    font-size: 40px;
}
.blue .price span {
    display: block;
    font-size: 15px;
    font-weight: 600;
}
.blue .price em {
    font-weight: 400;
    padding-top: 5px;
    font-style: normal;
    display: block;
    font-size: 14px;
}
.blue .price u {
    display: block;
    font-size: 15px;
    margin-bottom: 10px;
    text-decoration: none;
}
.blue .price u hr {
    margin: -10px 60px 12px;
    border: 0;
    border-top: 1px solid #ff0000;
}
.blue .price i {
    font-size: 14px;
    font-style: normal;
    vertical-align: top;
    display: inline-block;
    line-height: 30px;
}
.blue .price small {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
}
.blue button {
    margin: 15px auto 25px auto;
    width: 84%;
    border: 0;
    background-color: #fff;
    padding: 20px 0;
    border-radius: 5px;
    text-transform: uppercase;
    display: block;
    color: #136fe0;
    font-weight: 600;
    font-size: 14px;
}
.beneficios {
    text-align: center;
    background-image: -webkit-linear-gradient(221deg, #05323e, #0b143b);
    background-image: linear-gradient(229deg, #05323e, #0b143b);
}
.beneficios::after,
.regulamento::after {
    content: '+';
    position: absolute;
    right: 24px;
    top: 0;
    margin: 0;
    padding: 0;
    line-height: 70px;
    color: #6fc713;
    font-size: 26px;
    font-weight: 400;
    z-index: 1;
}
.tool {
    cursor: pointer;
    z-index: 2;
    position: relative;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    background-image: url('../images/icn_benefits.svg');
    background-repeat: no-repeat;
    padding: 25px 0 25px 0;
    background-position: 20px center;
}
.tool strong {
    font-size: 20px;
    line-height: 14px;
    vertical-align: middle;
    color: #3383dc;
    display: none;
}
.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}
.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
}
/*== pointer tip ==*/
.tool::before {
    border-style: solid;
    border-width: 0 0.75em 1em 0.75em;
    border-color: transparent transparent #3383dc transparent;
    top: 75%;
    content: '';
    margin-left: -0.5em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
        opacity 0.65s 0.5s;
    transform: scale(0.6) translateY(-90%);
    font-size: 11px !important;
}
.tool:hover::before,
.tool:focus::before {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    top: 85%;
}
.tool::after {
    background: #3383dc;
    border-radius: 10px;
    top: 0;
    color: #fff;
    content: attr(data-tip);
    margin-left: -51%;
    padding: 1em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateY(50%);
    width: 90%;
    font-size: 14px;
    text-align: center;
    text-transform: none;
}
.tool:hover::after,
.tool:focus::after {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
    top: 100%;
}
.tool-fixed::after {
    border-style: solid;
    border-width: 0 1em 2em 1em;
    border-color: transparent transparent #004691 transparent;
    top: -5px;
    content: '';
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
        opacity 0.65s 0.5s;
    transform: scale(0.6) translateY(-90%);
    font-size: 11px !important;
    position: absolute;
    z-index: 9999;
}
.tool-fixed {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    border-radius: 7px;
    margin-bottom: 20px;
    background-color: #004691;
    text-align: center;
}
.bd-01::after {
    left: 5%;
}
.bd-07::after {
    left: 22%;
}
.bd-10::after {
    left: 39%;
}
.bd-12::after {
    left: 55%;
}
.bd-15::after {
    left: 73%;
}
.bd-20::after {
    left: 90%;
}
.blue .name_offer {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 0 10px 0;
    color: #6fc713;
}
.blue .name_offer hr {
    background-color: #6fc713;
    border: 0;
    height: 3px;
    width: 30%;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 15px;
}
.blue .bonus-bank {
    text-align: center;
    display: block;
    padding: 15px 0 20px 0;
    text-decoration: none;
    font-size: 13px;
    color: #fff;
    position: relative;
    line-height: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 20px;
}
.blue .bonus-bank img {
    width: 90px;
    height: auto;
    display: block;
    margin: 10px auto;
}
.blue .bonus-bank strong {
    color: #6fc713;
    font-size: 20px;
}
.blue .regulamento {
    text-align: center;
    background-image: -webkit-linear-gradient(221deg, #05323e, #0b143b);
    background-image: linear-gradient(229deg, #05323e, #0b143b);
    display: block;
    padding: 15px 50px 20px 50px;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    border-radius: 0 0 10px 10px;
    position: relative;
}
.blue .regulamento strong {
    display: block;
}
.blue .regulamento:hover strong {
    text-decoration: underline;
}
.blue .regulamento::before {
    content: '.';
    background-image: url('../images/icn_regulations.svg');
    background-repeat: no-repeat;
    display: block;
    width: 26px;
    height: 28px;
    position: absolute;
    left: 20px;
}
.popup {
    display: table;
    background-image: -webkit-linear-gradient(256deg, #05323e, #0b143b);
    background-image: linear-gradient(194deg, #05323e, #0b143b);
    height: 100vh;
    width: 100%;
    background-size: cover;
    padding-bottom: 50px;
}
.popup .head {
    padding: 20px 20px;
}
.popup .head img {
    width: 80px;
    height: auto;
}
.popup .head a {
    position: absolute;
    right: 20px;
}
.popup .head a img {
    width: 30px;
    height: auto;
}
.popup .form-group {
    margin: 0 auto;
    padding-top: 0px;
    position: relative;
    overflow-y: auto;
}
.popup .form-group h4 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.popup .form-group h2 {
    font-weight: 700;
    text-align: center;
    font-size: 36px;
    color: #6fc713;
}
.popup .form-group h2 strong {
    font-size: 36px;
}
.popup .form-group h2 span {
    display: block;
    font-size: 13px;
    letter-spacing: -1px;
    padding-top: 5px;
    color: #fff;
    font-weight: 600;
}
.popup .form-group h3 {
    font-size: 14px;
    color: #fff;
    text-align: center;
    padding-bottom: 10px;
}
.popup .form-group .apps {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 20px;
}
.popup .form-group .apps img {
    margin: 0 3px;
}
.popup .form-group img {
    width: 25px;
    height: 25px;
}
.popup .form-group .ligacoes {
    padding: 10px 0;
}
.popup .form-group .redes-sociais {
    text-align: center;
}
.popup .form-group .redes-sociais h5 {
    color: #fff;
    font-size: 14px;
    padding-bottom: 10px;
}
.popup .form-group .redes-sociais img {
    width: 25px;
    height: 25px;
    margin: 0 3px;
}
.popup .form-group .price {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 10px;
    padding: 10px 0;
    text-align: center;
    color: #fff;
}
.popup .form-group .price strong {
    font-size: 30px;
}
.popup .form-group .price span {
    display: block;
    font-size: 13px;
    font-weight: 600;
}
.popup .form-group .price em {
    font-weight: 400;
    padding-top: 5px;
    font-style: normal;
    display: block;
    font-size: 12px;
}
.popup .form-group .plan {
    background-image: -webkit-linear-gradient(221deg, #05323e, #0b143b);
    background-image: linear-gradient(229deg, #05323e, #0b143b);
    padding: 15px;
    border-radius: 10px;
}
.popup .form-group p {
    padding-bottom: 0;
}
/* Forms */
.popup .form-group input,
.login form input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 7px;
    min-height: 50px;
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
    padding-left: 15px;
}
.popup .form-group select {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 7px;
    height: 50px;
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px;
    padding-left: 15px;
}
.popup .form-group select option {
    padding-left: 15px;
    display: block;
    color: #000000;
}
::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}
::placeholder {
    color: #fff;
}
/* Buttons */
.popup .form-group input[type='submit'],
.login form button {
    width: 100%;
    border: 0;
    background-color: #fff;
    padding: 20px 0;
    border-radius: 5px;
    text-transform: uppercase;
    color: #136fe0;
    font-weight: 600;
    font-size: 14px;
    -moz-appearance: none;
    -webkit-appearance: none;
    /* margin-bottom: 60px; */
    margin-bottom: 30px;
}
/* Checkbox */
input[type='checkbox'] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    text-align: left;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}
input[type='checkbox'] {
    display: none;
}
input[type='checkbox'] + label:before {
    content: 'âœ”';
    background: #3383dc;
    border-radius: 4px;
    display: inline-block;
    width: 14px;
    height: 20px;
    margin-right: 8px;
    color: transparent;
    transition: 0.2s;
    vertical-align: middle;
    font-size: 15px;
    margin-left: -2px;
    padding: 4px 7px;
}
input[type='checkbox'] + label:active:before {
    transform: scale(0);
}
input[type='checkbox']:checked + label:before {
    color: #fff !important;
}
input[type='checkbox']:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
}
input[type='checkbox']:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}
.vencimento {
    overflow: hidden;
}
.vencimento [type='radio']:checked,
.vencimento [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}
.vencimento [type='radio']:checked + label,
.vencimento [type='radio']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    text-align: center !important;
    color: #fff;
    font-weight: bold;
    line-height: 50px;
    font-size: 18px;
    background-color: #3383dc;
    margin-bottom: 20px;
}
.vencimento [type='radio']:checked + label:before,
.vencimento [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
    display: none;
}
.vencimento [type='radio']:checked + label:after,
.vencimento [type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #f87da9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: none;
}
.vencimento [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.vencimento [type='radio']:checked + label {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #6fc713;
    color: #fff;
}
.slick-next {
    width: 45px;
    height: 45px;
    background-color: rgba(0, 50, 100, 0.8);
    right: -13px;
    top: 260px;
    border-radius: 100px;
    background-image: url('../images/arrows.png');
    background-repeat: no-repeat;
    background-position: right center;
}
.slick-prev {
    width: 45px;
    height: 45px;
    background-color: rgba(0, 50, 100, 0.8);
    left: -13px;
    top: 260px;
    border-radius: 100px;
    background-image: url('../images/arrows.png');
    background-repeat: no-repeat;
    background-position: left center;
    z-index: 9999;
}
.slick-disabled {
    display: none !important;
}
.slick-next,
.slick-prev {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}
@keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}
.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
.atendimento {
    background-color: #e0e7f2;
    padding: 40px 0 60px 0;
    margin-top: 60px;
    border-bottom: 1px solid #82b9e6;
}
.atendimento h4 {
    color: #004691;
    font-size: 24px;
    text-align: center;
    padding-bottom: 40px;
}
.atendimento h4 span {
    display: block;
    font-weight: 400;
    font-size: 15px;
    padding-top: 10px;
}
.atendimento .row {
    margin: 0 auto;
}
.atendimento .row img {
    width: 60px;
    height: auto;
}
.atendimento a {
    display: block;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 15px;
    padding: 25px 0;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25);
    margin: 0 15px;
    border-bottom: 5px solid #3383dc;
    border-top: 5px solid #3383dc;
}
.atendimento a h5 {
    font-size: 22px;
    margin-top: 15px;
    color: #004691;
    text-decoration: none;
}
.atendimento a h5 span {
    display: block;
    font-size: 18px;
    font-weight: 400;
}
footer {
    display: block;
    padding: 50px 0 0 0;
}
footer h4 {
    color: #fff;
    font-size: 24px;
    text-align: center;
    padding-bottom: 40px;
}
footer h4 span {
    display: block;
    font-weight: 400;
    font-size: 16px;
    padding-top: 10px;
}
footer .col {
    overflow: hidden;
    margin-bottom: 30px;
}
footer i {
    display: inline-block;
    width: 25%;
}
footer p {
    display: inline-block;
    color: #fff;
    width: 75%;
    font-size: 13px;
}
footer p strong {
    display: block;
    font-size: 16px;
}
.pin-whatsapp {
    position: fixed;
    z-index: 9;
    bottom: 30px;
    right: 30px;
}
.pin-whatsapp img {
    width: 80px;
    height: auto;
}
.erro {
    color: #f6d438;
    font-size: 15px;
    margin-bottom: 15px;
}
.erro i {
    font-style: normal;
    background-color: #f6d438;
    color: #000;
    display: inline-block;
    padding: 0px 7px;
    border-radius: 50px;
    margin-right: 5px;
}

span.error {
    display: block;
}

span.inline {
    display: inline-block;
}

p.error,
span.error {
    color: #ff0036;
    font-size: 11px;
    margin-bottom: 10px;
}

.popup-erro {
    background-color: rgba(28, 65, 143, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 99999;
}
.popup-erro .content-popup {
    background-color: #fff;
    padding: 20px;
    margin: 0 auto;
    top: 100px;
    border-radius: 10px;
    text-align: left;
}
.popup-erro .content-popup h4 {
    color: #01508c;
    font-size: 24px;
    font-family: 'TIM Sans';
    font-weight: bold;
}
.popup-erro .content-popup h4 i {
    display: inline-block;
    vertical-align: middle;
    width: 15%;
}
.popup-erro .content-popup h4 span {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    line-height: 24px;
}
.popup-erro .content-popup p {
    color: #01508c;
    font-size: 17px;
}
.popup-erro .content-popup a {
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 15px;
}
.popup-erro .content-popup .card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px auto 15px auto;
    background-color: #5aa012;
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 0.866;
    outline: 0;
    text-transform: uppercase;
    -webkit-transition: 0.2s ease-in-out background-color;
    -moz-transition: 0.2s ease-in-out background-color;
    transition: 0.2s ease-in-out background-color;
}
.popup-erro .content-popup .call {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px auto 15px auto;
    background-color: #01508c;
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 0.866;
    outline: 0;
    text-transform: uppercase;
    -webkit-transition: 0.2s ease-in-out background-color;
    -moz-transition: 0.2s ease-in-out background-color;
    transition: 0.2s ease-in-out background-color;
}
.popup-erro .content-popup h4 i {
    width: 25%;
}
.popup-erro .content-popup h4 span {
    width: 75%;
}
.tag {
    margin: 0 auto;
    text-align: center;
    padding: 7px 0;
    top: -15px;
    background-color: #6fc713;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 6px;
    color: #2e5289;
    font-weight: 600;
    position: absolute;
    width: 200px;
    z-index: 99999;
    display: inline-block;
    left: 50%;
    margin-left: -100px;
}
.eco {
    background-color: #8cd0f2;
}
.login,
.success {
    display: table;
    height: 100vh;
    width: 100%;
    background: #05103e;
    background-size: cover;
}
.login .banner {
    background-size: cover;
    background-position: center;
    background-color: #002198;
    padding-bottom: 50px;
    min-height: 270px;
}
.login header h1 {
    text-align: center;
}
.login header {
    background-color: #002198;
}
.login .banner h2 {
    padding-top: 50px;
}
.login form {
    margin: 0 auto;
    padding-top: 30px;
}
.login form h2 {
    padding-bottom: 30px;
    text-align: center;
    color: #6fc713;
}
/* .success {
    background-image: -webkit-linear-gradient(256deg, #05323e, #0b143b);
    background-image: linear-gradient(194deg, #05323e, #0b143b);
} */
.success header {
    background-color: transparent;
    text-align: left;
}
.success .grid_6 {
    margin: 0 auto;
}
.success h1 {
    padding-top: 50px;
    color: #fff;
    font-size: 30px;
    text-align: center;
    display: inline-block;
}
.success h1 strong {
    text-transform: uppercase;
    color: #88cc70;
}
.success h2 {
    padding-top: 50px;
    color: #fff;
    font-size: 25px;
    text-align: center;
    display: inline-block;
    font-weight: 400;
}
.success h2 img {
    width: 120px;
    height: auto;
}
.success h2 strong {
    color: #88cc70;
}
.success a {
    color: #6fc713;
    display: block;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
}
.success h2 span {
    display: block;
    color: #fff;
    font-size: 17px;
}
.success h2 .app_bank {
    display: inline-block;
}
.success h2 .app_bank img {
    width: 120px;
    height: auto;
}
.m2b-footer {
    display: block;
    position: static;
    text-align: center;
    background-color: transparent !important;
}
.m2b-footer h6 {
    width: 100%;
    border-top: 1px solid #dedcdc;
    padding: 25px 0;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    color: #dedcdc;
}
.m2b-footer a {
    text-decoration: none;
    font-weight: bold;
    color: #dedcdc;
}
footer .bank-c6 {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 0 0 0;
    color: #fff;
    text-align: center;
}
footer .bank-c6 img {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    height: auto;
    margin-left: 5px;
}
footer .bank-c6 a {
    color: #6fc713;
    display: block;
    margin-top: 10px;
}
footer .bank-c6 .app_bank {
    display: inline-block;
}
footer .bank-c6 .app_bank img {
    width: 110px;
    height: auto;
}

/* Popup User Inactive*/
.popup-box {
    display: none;
    position: fixed;
    background: rgba(255, 255, 255, 0.7);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
}

.popup-inner {
    max-width: 903px;
    min-height: 449px;
    background-color: #0b4691;
    border-radius: 10px;
    padding: 40px 35px;
    margin: auto;
    top: 10%;
    position: relative;
}

.popup-inner .close {
    position: absolute;
    right: 19px;
    top: 15px;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.popup-inner .close:focus {
    outline: none;
}

.popup-inner .close:hover {
    opacity: 0.7;
}

.popup-msg h1 {
    color: #fff;
    font-size: 32px;
    text-align: left;
    font-weight: normal;
}

.popup-details {
    padding: 30px 25px 0 0;
    border-right: 1.5px solid rgba(255, 255, 255, 0.2);
}

.popup-details .giga h2 {
    font-size: 54px;
    font-weight: bold;
    color: #6fc713;
    border-right: 1.5px solid rgba(255, 255, 255, 0.2);
}

.popup-details .giga-details {
    font-size: 16px;
    color: #ffffff;
    padding: 14px 0 14px 12px;
    display: table;
    vertical-align: middle;
}

.popup-details .giga-details strong {
    color: #6fc713;
}

.popup-details .giga-details img {
    width: 78px;
    height: auto;
}

.popup-details .benefits {
    border-top: 1.5px solid rgba(255, 255, 255, 0.2);
    margin: 25px 0 0 0;
    padding: 25px 0 0 0;
}

.popup-details .benefits div {
    background-color: rgba(9, 23, 60, 0.3);
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 18px 0;
    min-height: 121px;
}

.popup-details .benefits h3 {
    font-size: 20px;
    color: #6fc713;
    font-weight: normal;
    margin-bottom: 20px;
}

.popup-details .benefits img {
    width: 35px;
    height: auto;
    margin-left: 8px;
}

.popup-details .benefits img:first-child {
    margin-left: 0;
}

.popup-details .benefits strong {
    display: block;
    font-size: 18px;
}

.popup-price {
    text-align: center;
    color: #fff;
}

.popup-price .old {
    font-size: 16px;
    font-weight: bold;
}

.popup-price .old strong {
    font-weight: normal;
    text-decoration: line-through;
    text-decoration-color: #ff0000;
    display: block;
}

.popup-price .price {
    font-size: 22px;
    padding: 15px 0;
}

.popup-price .price strong {
    font-size: 50px;
}

.popup-price .price b {
    font-size: 27px;
    vertical-align: top;
}

.popup-price .validity {
    font-size: 14px;
    margin-bottom: 10px;
}

.popup-price .invoice {
    font-size: 14px;
    margin-bottom: 40px;
}

.popup-price .invoice strong {
    display: block;
}

.next-step-btn {
    background: #ffffff;
    border: none !important;
    padding: 18px 33px !important;
    color: #136fe0 !important;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin: 0 auto;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
}

.transparent-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.3;
    overflow: hidden;
}
/* Overlay Carregamento */
@keyframes ldio-kotnoqzfdo {
    0% {
        opacity: 1;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1.5, 1.5);
    }
    100% {
        opacity: 0;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1, 1);
    }
}
.overlay-container {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
}
.ldio-kotnoqzfdo div > div {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    animation: ldio-kotnoqzfdo 1s linear infinite;
}
.ldio-kotnoqzfdo div:nth-child(1) > div {
    left: 148px;
    top: 88px;
    animation-delay: -0.875s;
}
.ldio-kotnoqzfdo > div:nth-child(1) {
    transform: rotate(0deg);
    transform-origin: 160px 100px;
}
.ldio-kotnoqzfdo div:nth-child(2) > div {
    left: 130px;
    top: 130px;
    animation-delay: -0.75s;
}
.ldio-kotnoqzfdo > div:nth-child(2) {
    transform: rotate(45deg);
    transform-origin: 142px 142px;
}
.ldio-kotnoqzfdo div:nth-child(3) > div {
    left: 88px;
    top: 148px;
    animation-delay: -0.625s;
}
.ldio-kotnoqzfdo > div:nth-child(3) {
    transform: rotate(90deg);
    transform-origin: 100px 160px;
}
.ldio-kotnoqzfdo div:nth-child(4) > div {
    left: 46px;
    top: 130px;
    animation-delay: -0.5s;
}
.ldio-kotnoqzfdo > div:nth-child(4) {
    transform: rotate(135deg);
    transform-origin: 58px 142px;
}
.ldio-kotnoqzfdo div:nth-child(5) > div {
    left: 28px;
    top: 88px;
    animation-delay: -0.375s;
}
.ldio-kotnoqzfdo > div:nth-child(5) {
    transform: rotate(180deg);
    transform-origin: 40px 100px;
}
.ldio-kotnoqzfdo div:nth-child(6) > div {
    left: 46px;
    top: 46px;
    animation-delay: -0.25s;
}
.ldio-kotnoqzfdo > div:nth-child(6) {
    transform: rotate(225deg);
    transform-origin: 58px 58px;
}
.ldio-kotnoqzfdo div:nth-child(7) > div {
    left: 88px;
    top: 28px;
    animation-delay: -0.125s;
}
.ldio-kotnoqzfdo > div:nth-child(7) {
    transform: rotate(270deg);
    transform-origin: 100px 40px;
}
.ldio-kotnoqzfdo div:nth-child(8) > div {
    left: 130px;
    top: 46px;
    animation-delay: 0s;
}
.ldio-kotnoqzfdo > div:nth-child(8) {
    transform: rotate(315deg);
    transform-origin: 142px 58px;
}
.loadingio-spinner-spin-yfxl1chk6xr {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
}
.ldio-kotnoqzfdo {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-kotnoqzfdo div {
    box-sizing: content-box;
}
/* Fim Popup User Inactive*/
@media (max-width: 768px) {
    .float-btn .float-btn-content {
        height: 60px;
        width: 170px;
    }
    .float-btn .float-btn-content i svg {
        width: 50px;
        height: 50px;
    }
    .float-btn .float-btn-content .float-btn-text {
        margin-left: 5px;
    }
    .float-btn .float-btn-content .float-btn-text p strong,
    .float-btn .float-btn-content .float-btn-text p strong em {
        font-size: 14px;
    }
    .float-btn .float-btn-content .float-btn-text p:last-child {
        font-size: 13px;
        margin-top: -15px;
    }

    header {
        padding-top: 20px;
    }

    header img {
        width: 70px;
    }
    header .numero-login {
        display: inline-block;
        margin-top: -23px;
    }
    header .numero-login p {
        font-size: 13px;
        margin-right: 0;
    }
    header .numero-login p::after {
        display: inline-block;
        height: 13px;
        margin-left: 5px;
        vertical-align: middle;
    }
    header .numero-login a {
        font-size: 13px;
    }

    .banner {
        background-image: url('../images/bg-mobile.png');
        background-size: cover;
        background-position: right;
        padding-bottom: 30px;
        height: 315px;
    }
    .banner .col {
        width: 100%;
    }
    .banner h2 {
        font-size: 25px;
        padding: 20px 0 0 0;
    }
    .banner h2 em {
        display: inline-block;
        font-size: 28px;
        margin-bottom: 10px;
    }
    .banner h2 strong {
        display: inline-block;
    }
    .banner h2 span {
        padding-top: 4px;
        display: block;
    }
    .banner h3 {
        font-size: 18px;
        font-weight: 400;
    }
    .banner p img {
        /* width: 90%; */
        width: 132px;
    }
    .banner em {
        font-size: 11px;
        line-height: 14px;
        display: block;
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .banner .giga-desconto {
        width: 270px;
    }
    .banner .giga {
        font-size: 44px;
    }
    .banner .line {
        height: 37px;
    }
    .banner .desconto {
        font-size: 13px;
    }
    .banner .desconto em {
        display: inline;
        padding: 0;
        font-size: 14px;
    }
    .banner .desconto strong {
        font-size: 26px;
    }
    .banner button[type='submit'] {
        margin: 11px 0 0 0;
        width: 100%;
        height: 51px;
        line-height: 51px;
        border-radius: 13px;
        font-size: 16px;
        float: none;
    }
    .banner .proce .banner .hero {
        position: absolute;
        top: 40px;
        width: 200px;
        height: auto;
        right: -60px;
    }
    .popup {
        height: auto;
    }
    /* .popup .head img {
        width: 60px;
        height: auto;
    } */
    .popup .head a img {
        width: 20px;
        height: auto;
    }
    .popup .form-group {
        padding: 0 20px;
    }
    .popup .plan {
        margin-bottom: 20px;
    }
    .vencimento .grid_2 {
        width: 15%;
        margin-left: 2%;
    }
    .vencimento .grid_2:first-child {
        margin-left: 0;
    }
    .atendimento {
        margin-top: 0;
    }
    .atendimento .col {
        width: 100%;
    }
    .atendimento a {
        margin: 0 5px;
        border-top: 0;
    }
    .atendimento a h5 {
        font-size: 16px;
    }
    .atendimento a h5 span {
        font-size: 13px;
    }
    .box {
        padding-bottom: 70px;
    }
    .center {
        padding-bottom: 25px;
    }
    .slick-dots {
        bottom: 140px !important;
        z-index: -2;
    }
    footer .container .row .col {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 10px;
    }
    footer .container .row .col img {
        width: 60px;
        height: auto;
    }
    footer .vazio {
        display: none;
    }
    footer .bank-c6 {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
        text-align: center;
    }
    footer .bank-c6 a {
        display: block;
        margin-top: 15px;
    }
    .success h2 {
        padding-top: 150px;
    }
    .pin-whatsapp {
        right: 5px;
        bottom: 5px;
    }
    .pin-whatsapp img {
        width: 75px;
    }
    .success h1 {
        line-height: 30px;
        font-size: 22px;
    }
    .success h2 {
        padding-top: 20px;
        font-size: 22px;
        padding-bottom: 20px;
        line-height: 30px;
    }
    .success h2 img {
        width: 90px;
        height: auto;
    }
    .m2b-footer {
        bottom: 0;
    }
    .popup-inner {
        max-width: 320px;
        min-height: 428px;
        background-position: 90% center;
        padding: 32px 20px 40px 20px;
        top: 2%;
        margin: 0 20px;
    }

    .popup-inner .close {
        right: 10px;
        top: 10px;
    }

    .popup-inner .close img {
        width: 15px;
        height: auto;
    }

    .popup-msg h1 {
        font-size: 18px;
    }

    .popup-details {
        padding: 20px 0 20px 0;
        border: none;
    }

    .popup-details .giga h2 {
        font-size: 40px;
        border: none;
        text-align: center;
    }

    .popup-details .giga-details {
        font-size: 14px;
        padding: 20px 0;
        text-align: center;
    }

    .popup-details .benefits {
        border: none;
        margin: 0;
        padding: 0;
    }

    .popup-details .benefits div {
        font-size: 10px;
        padding: 11px;
        min-height: 92px;
        width: 48%;
        margin-left: 2%;
    }

    .popup-details .benefits div:first-child {
        margin-left: 0;
    }

    .popup-details .benefits h3 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .popup-details .benefits img {
        width: 25px;
        height: auto;
        margin-left: 3px;
    }

    .popup-details .benefits strong {
        font-size: 14px;
    }

    .popup-price {
        padding: 15px 0 0 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    .popup-price .old {
        font-size: 14px;
    }

    .popup-price .price {
        font-size: 18px;
        padding: 5px 0;
    }

    .popup-price .price strong {
        font-size: 36px;
    }

    .popup-price .price b {
        font-size: 18px;
    }
    /* Overlay Carregamento */
    .ldio-kotnoqzfdo div > div {
        width: 10px;
        height: 10px;
    }
}
@media (max-width: 320px) {
    .banner h2 {
        font-size: 24px;
    }
    .banner h3 {
        font-size: 15px;
    }
    .banner em {
        font-size: 10px;
    }
    .banner .hero {
        width: 190px;
    }
    .atendimento a h5 span {
        font-size: 12px;
    }
    .popup-msg h1 {
        font-size: 16px;
    }

    .popup-details {
        padding: 10px 0 20px 0;
        border: none;
    }

    .popup-details .giga h2 {
        font-size: 25px;
    }

    .popup-details .giga-details {
        font-size: 12px;
        padding: 10px 0;
    }

    .popup-price .price strong {
        font-size: 25px;
    }
}
